import { render, staticRenderFns } from "./ChatMenu.vue?vue&type=template&id=1452e5c4&"
import script from "./ChatMenu.vue?vue&type=script&lang=ts&"
export * from "./ChatMenu.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QMenu,QList,QItem,QItemSection,QSeparator,QItemLabel,QToggle,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QMenu,QList,QItem,QItemSection,QSeparator,QItemLabel,QToggle})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
