





































































































































































































import {
  showLeaveGroupDialog,
} from '@/components/Modals/Default/Dialog/Presets'
import store, { chatsBarStore, groupsStore, teamsStore, uiStore, rootStore, contactsStore } from '@/store'
import {
  TOGGLE_CHAT_EXCLUDED_STATE,
  TOGGLE_CHAT_PIN_STATE,
} from '@/store/actionTypes'
import { Chat, Contact } from '@tada-team/tdproto-ts'
import { QMenu } from 'quasar'
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

@Component
export default class ChatMenu extends Vue {
  @Prop({ type: Object, required: true }) private readonly chat!: Chat
  @Prop({ type: Boolean }) private readonly showHide?: boolean
  @Prop({ type: Boolean }) private readonly showPin?: boolean
  @Prop({ type: Boolean }) private readonly showSystembot?: boolean

  @Ref() menu!: QMenu

  private groupNotifications = false
  private systembotNotifications = false

  @Getter private readonly profile!: Contact
  @Getter private readonly chatIsThread: any

  private get currentTeamId () {
    return window.currentTeamId ?? teamsStore.state.currentTeamId
  }

  private get isDirect () {
    return this.chat.chatType === 'direct'
  }

  private get isGroup () {
    return this.chat.chatType === 'group'
  }

  isThread () {
    return this.chatIsThread(this.chat.jid)
  }

  private get isSingleGroupTeam () {
    return teamsStore.getters.isSingleGroupTeam(this.currentTeamId)
  }

  private get autocleanupCaption () {
    if (this.chat.autocleanupAge === undefined) return

    const age = this.chat.autocleanupAge
    const m = 60
    const h = m * 60
    const d = h * 24

    const data = []

    const days = Math.floor(age / d)
    days > 0 && data.push(days + this.$t('common.day_short').toString())

    const hours = Math.floor((age - days * d) / h)
    hours > 0 && data.push(hours + this.$t('common.hour_short').toString())

    const min = Math.round((age - days * d - hours * h) / m)
    min > 0 && data.push(min + this.$t('common.minute_short').toString())

    return data.join(' ')
  }

  private get canChangeAutocleanupAge () {
    return this.chat.changeableFields?.includes('autocleanup_age')
  }

  private get showAutodeleteSettings () {
    const canChange = this.chat.changeableFields?.includes('autocleanup_age')
    return canChange || this.chat.autocleanupAge !== undefined
  }

  private get showOpenSettings () {
    if (this.chat.canDelete) return true
    if (!this.chat.changeableFields) return !!this.chat.canChangeSettings

    const fields = [
      'display_name', 'description', 'icons', 'public',
      'default_for_all', 'readonly_for_members',
    ]

    for (const field of fields) {
      if (this.chat.changeableFields.includes(field)) return true
    }

    return false
  }

  private get groupNotificationsFromStore () {
    return this.chat.notificationsEnabled ?? false
  }

  private get systembotNotificationsFromStore () {
    return this.profile.systembotNotificationsEnabled ?? false
  }

  private get isDev () {
    return window.FEATURES.is_testing
  }

  @Watch('groupNotificationsFromStore', { immediate: true })
  private updateGroupNotifications (value: boolean) {
    this.groupNotifications = value
  }

  @Watch('systembotNotificationsFromStore', { immediate: true })
  private updateSystembotNotifications (value: boolean) {
    this.systembotNotifications = value
  }

  private show () {
    this.menu.show()
  }

  private hide () {
    this.menu.hide()
  }

  private onAddMembersClick () {
    this.goal('Клик «Добавить участников»')
    uiStore.actions.showModal({
      instance: 'all-members',
      payload: { jid: this.chat.jid, edit: true },
    })
  }

  private onAutocleanupClick () {
    this.goal('Клик «Автоудаление»')
    uiStore.actions.showModal({
      instance: 'AutodeleteSettings',
      payload: { chatId: this.chat.jid },
    })
  }

  private onHideClick () {
    this.goal('Клик «В архив»')
    const chat = Chat.fromJSON({ ...this.chat.toJSON(), hidden: true })

    chatsBarStore.mutations.addOrUpdateChats([chat])

    store.dispatch(TOGGLE_CHAT_EXCLUDED_STATE, {
      chatId: this.chat.jid,
      exclude: !this.chat.hidden,
    })
  }

  private onLeaveClick () {
    this.goal('Клик «Покинуть чат»')
    if (!this.isGroup || this.isSingleGroupTeam) return
    showLeaveGroupDialog(this.chat.jid)
  }

  private onOpenInNewTabClick () {
    this.goal('Клик «Открыть в отдельном окне»')
    window.open(
      `/${this.currentTeamId}/chats/${this.chat.jid}/headless`,
      '_blank',
    )
  }

  private onOpenProfileClick () {
    this.goal('Клик «Профиль»')
    let instance: string, payload: string
    if (this.isSingleGroupTeam) {
      instance = 'team-profile'
      payload = this.currentTeamId
    } else {
      instance = this.isGroup ? 'group-profile' : 'contact-profile'
      payload = this.chat.jid
    }
    uiStore.actions.switchRightBarInstance({ instance, payload })
  }

  private onOpenSettingsClick () {
    this.goal('Клик «Настройки»')
    uiStore.actions.showModal({
      instance: 'GroupForm',
      payload: { type: 'edit', jid: this.chat.jid },
    })
  }

  private onToggleCountersEnabled (value: boolean) {
    this.goal('Клик «Счётчик непрочитанных»')

    const chat = Chat.fromJSON(this.chat.toJSON())
    chat.countersEnabled = value

    chatsBarStore.mutations.addOrUpdateChats([chat])

    groupsStore.actions.edit({
      groupId: chat.jid,
      payload: { counters_enabled: value },
    })
  }

  private async onChangeGroupNotifications (value: boolean) {
    this.goal('Клик «Уведомления»')
    this.updateGroupNotifications(value)
    try {
      await groupsStore.actions.edit({
        groupId: this.chat.jid,
        payload: { notifications_enabled: value },
      })
    } catch (e) {
      // TODO: refactor this
      setTimeout(() => {
        this.groupNotifications = this.groupNotificationsFromStore
      }, 300)
      throw e
    }
  }

  private async onChangeSystembotNotifications (value: boolean) {
    this.updateSystembotNotifications(value)
    if (value) {
      window.goal('chatsBarItemMenu', { chatsBarItemMenu: 'ВКЛ пуши Тадабот' })
    } else {
      window.goal('chatsBarItemMenu', { chatsBarItemMenu: 'ВЫКЛ пуши Тадабот' })
    }
    try {
      await contactsStore.actions.editContact({
        jid: this.profile.jid,
        params: { systembot_notifications_enabled: value },
      })
    } catch (e) {
      // TODO: refactor this
      setTimeout(() => {
        this.systembotNotifications = this.systembotNotificationsFromStore
      }, 300)
      throw e
    }
  }

  private onTogglePinClick () {
    const chat = Chat.fromJSON(this.chat.toJSON())

    this.goal(chat.pinned ? 'Клик «Открепить чат»' : 'Клик «Закрепить чат»')

    chat.pinned = !chat.pinned
    chat.pinnedSortOrdering = chat.pinned ? 1 : undefined

    chatsBarStore.mutations.addOrUpdateChats([chat])

    store.dispatch(TOGGLE_CHAT_PIN_STATE, {
      chatId: this.chat.jid,
      pinned: chat.pinned,
    })
  }

  private goal (msg: string) {
    window.goal('chatMenu', { chatMenu: msg, type: this.chat.chatType })
  }

  private get isNotificationsDisabled (): boolean {
    return !rootStore.state.isNotificationsAllowed
  }

  private onOpenDebugClick () {
    const payload = {
      title: 'Chat debug',
      content: this.chat,
    }

    this.$q.dialog({
      component: () => import('@/components/Modals/DebugModal.vue'),
      parent: this,
      payload,
    })
  }
}
